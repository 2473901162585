import {useState, useEffect} from "react";
import './App.scss';

function App() {
    const [active, setActive] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            setActive(true)
        }, 1500);
    })

    return (
        <div id="both" className={`search ${active ? "open" : ""}`}>
            <input placeholder={"Coming soon..."} readOnly={true} type="search" className="search-box"/>
            <span className="search-button" onClick={() => setActive(!active)}>
                    <span className="search-icon"/>
                </span>
        </div>
    );
}

export default App;
